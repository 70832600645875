import React from 'react';
import { Link } from 'react-router-dom';

interface MenuItem {
  id: number;
  link: string;
  target?: string;
  name: string;
}

const Header = ({ menuItems }:{menuItems:MenuItem[]}) => {
  return (
    <header className='header'>
        <header className="logo"><h1><a href="https://www.rebeccaprinn.co.uk">Rebecca Prinn</a></h1>
        </header>
      <nav>
        <img src='https://rebeccaprinn.blob.core.windows.net/logos/surface-pattern-and-illustration-image.png' alt="Surface Pattern Design and Illustration" />
      <ul className='horizontal-list'>
        {menuItems.map(item => (
          <li key={item.id} className='menuItem'>
            <Link to={item.link} target={item.target}>{item.name}</Link>
          </li>
        ))}
      </ul>
      </nav>
    </header>
  );
};

export default Header;
