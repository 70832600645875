import React from 'react';
import SEO from '../components/seo';

function About() {
    return (
        <div className="homePage">
        <SEO
title='About'
description='About Rebecca Prinn, a Yorkshire based designer with a background in greetings cards, illustration, graphics, management and training'
name='Rebecca Prinn'
type='About Rebecca Prinn - The Yorkshire Designer' />
        
            <img src='https://rebeccaprinn.blob.core.windows.net/logos/BeckyPrinn.jpg' alt="Rebecca Prinn" />
            <p>I'm a surface pattern designer and illustrator, living in West Yorkshire, mostly known for my expressive, painterly florals.  </p>
            <p>I worked in the greeting card industry for 9 years, before deciding to branch out and go freelance.  I now work from a studio (with amazing light), allowing me to get creative with the paints again.</p>
            <p>I love being able to license my work to so many different products, including fabric, greeting cards, paper goods and wall art.</p>  
            <p>My proudest moments have been collaborating with dream brands including Anthropologie, WHSmith and Hallmark. </p>
            <p>You can find my most recent work here on my website, and on <a href="https://www.instagram.com/becky.prinn" target="_blank" rel="noreferrer">Instagram</a></p>
        
        </div>
    );
}

export default About;
