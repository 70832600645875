import React from 'react';
import { Helmet } from 'react-helmet-async';

function SEO({ title, description, name, type }:{ title:string, description:string, name:string, type:string }) {
    const formatTitle = `Rebecca Prinn | ${title.charAt(0).toUpperCase()}${title.slice(1)} | Yorkshire based designer with a background in greetings cards, illustration, graphics, management and training`;

    return (
        <Helmet>
            <title>{formatTitle}</title>
            <meta name='description' content={description} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={formatTitle} />
            <meta property="og:description" content={description} />
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={formatTitle} />
            <meta name="twitter:description" content={description} />
        </Helmet>
    );
}

export default SEO;
