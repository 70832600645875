import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header'
import ImageGrid from './components/imageGrid';
import Portfolio from './pages/portfolio';
import About from './pages/about';
import Home from './pages/home';
import Secret from './pages/secret';
import Blog  from './pages/blog';
import {HelmetProvider } from 'react-helmet-async';

interface MenuItem {
  id: number;
  link: string;
  target?: string;
  name: string;
}

const menuItems:MenuItem[] = [
  { id: 1, name: 'Portfolio', link: '/portfolio' },
  { id: 2, name: 'About', link: '/about' },
  { id: 3, name: 'Blog', link: '/blog' },
  { id: 4, name: 'Buy', link: 'https://thortful.pxf.io/ZQE4ek', target:'_blank' },
];

const portfolioKey = process.env.REACT_APP_Portfolio;
const secretKey = process.env.REACT_APP_Secret;
const blogKey = process.env.REACT_APP_Blog;

function App() {
  const helmetContext = {};
 return (
  <HelmetProvider context={helmetContext}>
      <Router basename="/">
        <Header menuItems={menuItems} />
        <div className='container'>
        <div className='content'>
        <Routes>
        <Route path="/" element={<Home key="home" />} />
        <Route path="/portfolio" element={<Portfolio key="portfolio" accountKey={portfolioKey} containerName='portfolio' title="Portfolio"/>} />
        <Route path="/secret" element={<Secret key="secret" accountKey={secretKey} containerName='secret'/>} />
        <Route path="/portfolio/:themeId" element={<ImageGrid key=":themeId" accountKey={portfolioKey} containerName='portfolio'/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/blog" element={<Blog accountKey={blogKey}/>} />
        </Routes>
        </div>
        </div>
      </Router>
      </HelmetProvider>
    );
}

export default App;
