import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BlobServiceClient } from '@azure/storage-blob';
import Masonry from 'react-masonry-css';
import SEO from '../components/seo';

function Portfolio({ accountKey, containerName, title }:{accountKey?:string, containerName:string, title:string}) {
  const [blobs, setBlobs] = useState<any[]>([]);
  const accountName = 'rebeccaprinn';
  const containerUrl = `https://${accountName}.blob.core.windows.net/${containerName}`;

  //console.log(accountKey);
  useEffect(() => {
    async function fetchBlobs() {
      const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${accountKey}`);
      const containerClient = blobServiceClient.getContainerClient(containerName);
    
      const blobs:any[] = [];
      for await (const blob of containerClient.listBlobsFlat()) {
        if(!/-/.test(blob.name))
        {
          blobs.push(blob);
        }
      }

      setBlobs(blobs);
    }

    fetchBlobs();
  }, [accountKey, containerName]);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
  };

  return (
    <div className="homePage">
<SEO
title={title}
description={'Portfolio around the theme of ' + containerName + ' by Rebecca Prinn Yorkshire based designer with a background in greetings cards, illustration, graphics, management and training'}
name='Rebecca Prinn'
type='Portfolio by Rebecca Prinn - The Yorkshire Designer' />

      <h2>{title}</h2>

      <Masonry
  breakpointCols={breakpointColumnsObj}
  className="imageGrid"
  columnClassName="imageGrid_column">
     {blobs.map(blob => (
      <div className="image-element-class">
        <Link key={blob.name} to={`/portfolio/${blob.name.split('.').slice(0, -1).join('.')}`}>
          <img key={blob.name} src={`${containerUrl}/${blob.name}`} alt={blob.tags?.description ?? blob.name} />
        </Link>
        </div>
        ))}
    </Masonry>
    </div>
  );
}

export default Portfolio;
