import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import SEO from '../components/seo';

interface BlogPost {
  id: string;
  title: string;
  published: string;
  content: string;
}

function Blog({ accountKey }:{accountKey?:string}) {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  Moment.locale('en');
        
  useEffect(() => {
    async function fetchPosts() {
      const response = await fetch(`https://www.googleapis.com/blogger/v3/blogs/334835795122110436/posts?key=${accountKey}`);
      const data = await response.json();
      setPosts(data.items);
    }

    fetchPosts();
  }, [accountKey]);

  return (
    <div className="homePage">
<SEO
title='Blog'
description='The Blog of Rebecca Prinn, Yorkshire based designer with a background in greetings cards, illustration, graphics, management and training'
name='Rebecca Prinn'
type='Rebecca Prinn Blog' />
      {posts.map(post => (
        <div className="blog" key={post.id}>
            <h3>{post.title}</h3>
<h4>{Moment(post.published).format('Do MMMM')}</h4>
            <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(post.content) }}></div>
        </div>
      ))}
    
    </div>
  );
}

export default Blog;
