import React, { useState } from 'react';

function Thumbnail(props:any) {
  const [modalVisible, setModalVisible] = useState(false);

  function handleThumbnailClick() {
    setModalVisible(!modalVisible);
  }

  function Modal(props:any) {
    return (
      <div className="modal-backdrop" onClick={props.onClose}>
        <div className="modal-content">
          <img src={props.src} alt={props.alt} />
        </div>
      </div>
    );
  }

  return (
    <div className="image-element-class">
    <a className="thumbnail-container" href={`#${props.alt.split('.').slice(0, -1).join('.')}`}>
     <img src={props.src} onClick={handleThumbnailClick} alt={props.alt} />
      {modalVisible && (
        <Modal
          src={props.src}
          onClose={() => setModalVisible(false)}
        />
      )}
      </a>
    </div>
  );
}

export default Thumbnail;