import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { useParams } from 'react-router-dom';
import Thumbnail from './thumbnail';
import Masonry from 'react-masonry-css';
import SEO from './seo';

function ImageGrid({ accountKey, containerName }:{accountKey?:string, containerName:string}) {
  const { themeId } = useParams();
  const [blobs, setBlobs] = useState<any[]>([]);
  const accountName = 'rebeccaprinn';
  const containerUrl = `https://${accountName}.blob.core.windows.net/${containerName}`;

  useEffect(() => {
    async function fetchBlobs() {
      const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${accountKey}`);
      const containerClient = blobServiceClient.getContainerClient(containerName);

      const blobs:any[] = [];
      for await (const blob of containerClient.listBlobsFlat()) {
        if (!themeId || (themeId && blob.name.includes(themeId))) {
          blobs.push(blob);
        }
      }
      setBlobs(blobs);
    }

    fetchBlobs();
  }, [accountKey, containerName, themeId]);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
  };

const pageTitle = containerName + (themeId ? ` - ${themeId}` : '');

  return (
    <div className="homePage">
    <SEO
title={pageTitle}
description={containerName + ' | Yorkshire based designer with a background in greetings cards, illustration, graphics, management and training'}
name='Rebecca Prinn'
type='About Rebecca Prinn - The Yorkshire Designer' />
      <h2>{pageTitle}</h2>

      <Masonry
  breakpointCols={breakpointColumnsObj}
  className="imageGrid"
  columnClassName="imageGrid_column">
     {blobs.map(blob => (
          <Thumbnail key={blob.name} src={`${containerUrl}/${blob.name}`} alt={blob.tags?.description ?? blob.name} />
        ))}
    </Masonry>
    </div>
  );
}

export default ImageGrid;
