import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import SEO from '../components/seo';

function Home() {

const [blobs, setBlobs] = useState<any[]>([]);
  const accountName = 'rebeccaprinn';
  const containerUrl = `https://${accountName}.blob.core.windows.net/brands`;

  useEffect(() => {
    async function fetchBlobs() {
      const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net`);
      const containerClient = blobServiceClient.getContainerClient("brands");
    
      const blobs:any[] = [];
      for await (const blob of containerClient.listBlobsFlat()) {
        if(!/-/.test(blob.name))
        {
          blobs.push(blob);
        }
      }

      setBlobs(blobs);
    }

    fetchBlobs();
  });
  
  return (
    <div>
<SEO
title='Rebecca Prinn'
description={'Rebecca Prinn Yorkshire based designer with a background in greetings cards, illustration, graphics, management and training'}
name='Rebecca Prinn'
type='Themes by Rebecca Prinn - The Yorkshire Designer' />
  <div className="homePage">
   
      <img src='https://rebeccaprinn.blob.core.windows.net/logos/hand-paining-floral.png' alt="hand painting floral" />
      <p>
      Thanks for stopping by.  I'm a freelance artist and designer, incorporating painting into my digital practice, to create joyful, expressive florals, for use on products.
     </p>   
      <img className="title" src='https://rebeccaprinn.blob.core.windows.net/logos/ways-to-work.png' alt="Ways to work with me" /> 
      
      <h2>PURCHASE OUTRIGHT</h2>
    <p>
A number of both artworks, and designs are available
to buy outright for personal or business use.
I sell giclee prints to order too.
    </p>
    <div className='subSection'>
      <img className="fullImage" src='https://rebeccaprinn.blob.core.windows.net/logos/art-for-sale-banner.png' alt="Floral artwork" /> 
  </div>
        
<h2>COMMISSION ME</h2><p>
I'd love to hear from you to discuss your latest project.
I'm experienced in creating greeting card ranges, and seamless patterns.
Get in contact to chat further.
</p>
    <img src='https://rebeccaprinn.blob.core.windows.net/logos/water-color-floral.png' alt="Water colour floral" /> 
    
    <h2>LICENSE MY WORK</h2>
<p>Seen something you like?
Get in touch and I can check availability, or send further examples.
Alternatively, contact my lovely agents
at <a href='https://www.advocate-art.com/'>Advocate Art</a>
</p><img src='https://rebeccaprinn.blob.core.windows.net/logos/fabric.png' alt="Fabric" /> 
     
<h2>HIRE ME AS A FREELANCER</h2>
    <div>
<p>I work with many big brands
as an occasional,
or regular freelancer.
I'd love to hear more
about your next project.</p>
<img src='https://rebeccaprinn.blob.core.windows.net/logos/acrylic-painting-florals.png' alt="Floral in progress" /> 
    <p>
Pop me an email
and we'll explore
whether we're a good fit.
My daily rate starts at £250.
</p>
    </div>

    <h2>BUY MY GREETING CARDS</h2>
    <p>
I have cards available online at <a href='https://thortful.pxf.io/ZQE4ek'>Thortful</a>.
If you use this link, I'll get a small proportion of the sale! If you're interested in large volumes, please do get in touch.
</p><img src='https://rebeccaprinn.blob.core.windows.net/logos/cards.png' alt="Greetings Cards" /> 
    
<h2>COLLABORATIONS</h2>
<p>
    {blobs.map(blob => (
          <img className="colabLogo" key={blob.name} src={`${containerUrl}/${blob.name}`} alt={blob.tags?.description ?? blob.name} />
        ))}    
</p>
    
<img src='https://rebeccaprinn.blob.core.windows.net/logos/floral.png' alt='Floral' className='fullSize' /> 
      
      </div>  
      </div>
      );
}

export default Home;
