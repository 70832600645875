import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import Thumbnail from '../components/thumbnail';

function Secret({ accountKey, containerName }:{accountKey?:string, containerName:string}) {
  const [blobs, setBlobs] = useState<any[]>([]);
  const accountName = 'rebeccaprinn';
  const containerUrl = `https://${accountName}.blob.core.windows.net/${containerName}`;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState("");

  const handleLogin = (event:any) => {
    event.preventDefault();
    if (password === process.env.REACT_APP_Password) {
      setIsLoggedIn(true);
    } else {
      alert("Invalid credentials");
    }
  };

    useEffect(() => {
      async function fetchBlobs() {
        const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${accountKey}`);
        const containerClient = blobServiceClient.getContainerClient(containerName);

        const blobs:any[] = [];
        for await (const blob of containerClient.listBlobsFlat()) {
            blobs.push(blob);
        }
        setBlobs(blobs);
      }

      fetchBlobs();
    }, [accountKey, containerName]);

    if (isLoggedIn) {
    return (
      <div className="homePage">
        <h2>{containerName}</h2>
        <div className="imageGrid">
          {blobs.map(blob => (
            <Thumbnail key={blob.name} src={`${containerUrl}/${blob.name}`} alt={blob.tags?.description ?? blob.name} />
          ))}
        </div>
      </div>
    );
  }
  else {
    return (
      <form onSubmit={handleLogin}>
        <input placeholder="password" key="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit">Sign In</button>
      </form>
    )
  }
}
export default Secret;
